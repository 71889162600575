<template>
	<lassoLayout>
		<div class="page-not-found">
			<div class="container" v-bind:class="[isActive ? 'active' : 'not-active']">
				<div class="wrap">
					<div class="animation-container">
						<div class="glitch-layer"></div>
					</div>
				</div>
				<div class="action-buttons">
					<router-link :to="{name: 'explore'}" class="tetriatary_btn medium" v-ripple>
						<span>Home</span>
					</router-link>
					<a href="mailto:admin@psychurch.org?subject=Bug%20Report" target="_blank" class="tetriatary_btn medium" v-ripple>
						<span>Report</span>
					</a>
				</div>
			</div>
		</div>
	</lassoLayout>
</template>

<script>
import lassoLayout from '@/layouts/lassoLayout.vue';

export default {
	name: '404',
	components: {
		lassoLayout
	},
	data() {
		return {
			isActive: true
		};
	},
	created() {
		// setInterval(() => {
		//     this.isActive = !this.isActive;
		// }, 1000);
	}
};
</script>

<style lang="scss">
.page-not-found {
	.wrap_buttons {
		.tetriatary_btn {
			width: 50% !important;
			margin: 0 10px !important;

			&:first-child {
				margin-left: 0 !important;
			}

			&:last-child {
				margin-right: 0 !important;
			}
		}
	}
}
</style>
